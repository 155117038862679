import React from 'react';
import './App.scss';
import NavHeader from "./Components/Nav"
import CTA from "./Components/CTA"
import AboutBody from './Components/AboutBody';
import ContactBody from './Components/ContactBody';
import Reviews from './Components/Reviews';
function App() {
  return (
    <main>
      <NavHeader />
      <CTA />
      <AboutBody />
      <ContactBody />
      <Reviews />
    </main>
  )
}

export default App;
