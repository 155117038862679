import logo from '../../images/Logo.png'

function NavHeader() {
  return (
    <div className="nav-body">
      <a href="/" className="nav-logo-body">
        <img src={logo} alt="logo" className="logo" />
      </a>
      <div className="link-body">
        <a href="/" className="link">
          Home
        </a>
        <a href="/about" className="link">
          About
        </a>
        <a href="/contact" className="link">
          Contact
        </a>
      </div>
      <div className="contact-body">
        <a className='link' href="tel:+9253393144">(925)339-3144</a>
        <span>Lic#1062800</span>
      </div>
    </div>
  )
}
export default NavHeader
