import React from 'react'

function AboutBody() {
  return (
    <div className="about-body">
      <div className="image-container">
        <div className="image-box"></div>
      </div>
      <div className="about-content-container">
        <h2>About</h2>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. A ipsum, accusamus numquam quis delectus hic minima et at modi saepe dolorem exercitationem amet suscipit quos quia deleniti, nesciunt animi id!</p>
      </div>
    </div>
  )
}

export default AboutBody