import React from 'react'
import wave from '../../images/wave.png'

function ContactBody() {
  return (
    <div className='contact-body'>
      <img src={wave} className='contact-wave contact-wave-reverse' alt="" />
      <form action="" className='contact-form'>
      <h1>Schedule Service or an Estimate</h1>
        <label htmlFor="name">Name</label>
        <input placeholder='John Doe' type="text" name="name" id="name" />
        <label htmlFor="email">Email</label>
        <input placeholder='example@email.com' type="email" name="email" id="email" />
        <label htmlFor="phone">Phone</label>
        <input placeholder="(123) 456 7890" type="tel" name="phone" id="phone" />
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" placeholder='Tell us a little about what you need done...' cols={30} rows={10}></textarea>
      </form>
      <img src={wave} className='contact-wave' alt="" />
    </div>
  )
}

export default ContactBody