import React, { useState } from 'react'

interface Review {
    name: string,
    review: number,
    location: string,
    service: string,
    message: string,
    date: Date,
}

export default function Reviews() {
    const [reviews, setReviews] = useState<Review[]>([
        {
            name: 'Harold L.',
            review: 5,
            location: 'Concord, CA',
            service: 'Install or Replace a Water Heater',
            message: 'Polite, efficient and very knowledgeable.',
            date: new Date("12/15/2020")
        },
        {
            name: 'Lauren Q.',
            review: 5,
            location: 'Concord, CA',
            service: 'Install, Repair or Replace Plumbing or Fixtures',
            message: 'Was very fast and efficient. When entering my house, he asked if I would like for him to take his shoes off as I have light colored carpet, which was nice of him to offer. I was very curious about the plumbing repair process and he walked me through what he was doing and clearly explained what he was doing. Did a wonderful job and very happy. I will definitely use him again if I have anymore plumping issues.',
            date: new Date("11/9/2020")
        },
        {
            name: 'James H.',
            review: 5,
            location: 'Oakland, CA',
            service: 'Install, Repair or Replace Plumbing or Fixtures',
            message: 'Quick install of toilet, a true professional',
            date: new Date("11/3/2020")
        },
        {
            name: 'Yasmine C.',
            review: 5,
            location: 'Vallejo, CA',
            service: 'Install, Repair or Replace Plumbing or Fixtures',
            message: 'Jason fixed the problem and he was very professional and very knowledgeable... would highly recommend him for your plumbing services... Thks in advance YC',
            date: new Date("10/30/2020")
        },
        {
            name: 'Janine D.',
            review: 5,
            location: 'Lafayette, CA',
            service: 'Install, Repair or Replace Plumbing or Fixtures',
            message: 'Jason was great. I had an urgent toilet repair problem and Jason was able to work me into his schedule for a Sunday repair. He took the time to stop by and assess the fix the night before. I was extremely impressed by his professionalism.',
            date: new Date("10/18/2020")
        },
        {
            name: 'Gaye R.',
            review: 5,
            location: 'Oakland, CA',
            service: 'Clear a Clogged or Slow Drain',
            message: 'Jason explained how I could manage the problem in the future which I appreciated.',
            date: new Date("12/26/2020")
        },
        {
            name: 'Armando J.',
            review: 5,
            location: 'Martinez, CA',
            service: 'Install, Replace or Repair a Main Water Pipe',
            message: 'Jason made us feel comfortable from the very first phone call. His communication throughout the job was excellent . Kept us informed on everything he was doing. He had a great work ethics .',
            date: new Date("12/17/2020")
        },
        {
            name: 'Leah P.',
            review: 5,
            location: 'Martinez, CA',
            service: 'Install, Repair or Replace Plumbing or Fixtures',
            message: 'Jason was very friendly, efficient and professional. He kept me informed of what he was finding and how he was going to repair the pipe leak. He even unclogged a bathroom sink he noticed was draining slow without me asking or charging for it. Also reasonably priced. Iâ ll definitely call him again for help! Iâ ve already started recommending him to my friends',
            date: new Date("12/23/2021")
        },
        {
            name: 'Connie T.',
            review: 5,
            location: 'Richmond, CA',
            service: 'Repair or Install Gas Piping',
            message: 'He kept me inform what time he would arrive at my home. Looked at what needed to be done, made the repairs which was very reasonable. I will recommend him yo others.',
            date: new Date("10/20/2021")
        },
        {
            name: 'Walied K.',
            review: 5,
            location: 'Yorba Linda, CA',
            service: 'Install or Repair a Toilet',
            message: 'Our home was built in the 80s so we needed to do a number of plumbing jobs. We are very happy that we found J\'s Plumbing who did 4 different jobs for us within the last month! They removed an old toilet and installed a new one, replaced a broken shower arm, unclogged the kitchen sink, uncovered the',
            date: new Date("1/25/2022"),
        }
    ])

    return (
        <div className="reviews-body">
            <h3>See what past customers have said:</h3>
            <h4>Brought to you by <a href="https://www.homeadvisor.com/rated.J39SPLUMBING.96985694.html">HomeAdvisor</a></h4>
            <div className="review-carousel-container">
                <div className="review-carousel">
                    {reviews.map((review, index) => {
                        return (
                            <div className="review-card" key={index}>
                                <div className="review-card-header">
                                    <h3>{review.name}</h3>
                                    <h3>{"🌟".repeat(review.review)}</h3>
                                    <p>{review.date.toLocaleDateString()}</p>
                                </div>
                                <div className="review-card-body">
                                    <h2>{review.service}</h2>
                                    <p>{review.message}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
