import React from 'react'
import logo from '../../images/Logo_with_phone.png'
import wave from '../../images/wave.png'

function CTA() {
  return (
    <div className="cta-body">
      <img src={logo} alt="j's plumbing logo" />
      <img src={wave} className='cta-wave' alt="" />
    </div>
  )
}

export default CTA